import { getYxList } from "./../api";
import editableCell from "./../../basicInformation/components/distributorManagement/components/editableCell";
import cancelreasonModal from "./../components/cancelReason.vue";
import moment from "moment";
import {
  getofficeList,
  getDLlnumSeach,
  getFXlnumSeach,
  getSupplierNameByOrg,
  getDLMDlnumSeach,
  activityApplication,
  getActivityInfo,
  getActivityCode, getMatklList,
  getActivityAttribute
} from "./api";
import axios from "axios";
import { publicPath } from "@/common/constant.js";
import { Number } from "core-js";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  components: {
    editableCell,
    cancelreasonModal,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    }
  },
  name: "",
  data() {
    return {
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
        {
          path: "/agentVerificationActivities",
          name: "agentVerificationActivities",
          title: "代理商市场活动"
        },
        {
          path: "/agentVerificationActivities/addOredit",
          name: "addOredit",
          title: "活动新增"
        }
      ],
      fetching: false,
      pageLoadFlag: false,
      detilInfo: {},
      columns: [
        {
          title: "种类",
          dataIndex: "type",
          // width: 400,
          scopedSlots: { customRender: "type" }
        },
        {
          title: "数量",
          dataIndex: "num",
          // width: 400,
          scopedSlots: { customRender: "num" }
        },
        {
          title: "单价",
          dataIndex: "price",
          // width: 400,
          scopedSlots: { customRender: "price" }
        },
        {
          title: "小计",
          dataIndex: "total",
          // width: 400,
          scopedSlots: { customRender: "total" }
        },
        {
          title: "备注",
          dataIndex: "remark",
          // width: 400,
          scopedSlots: { customRender: "remark" }
        }

      ],
      selectedRowKeys: [],
      selectedRowKeyscypl:[],
      MTselectedRowKeys: [],
      lcselectedRowKeys: [],
      zpselectedRowKeys: [],
      TOselectedRowKeys: [],
      otherselectedRowKeys: [],
      tableData: [], // 物料组数据
      MttableData: [],// 媒体宣传数据
      lctableData: [], // 临促数据
      zptableData: [], // 赠品数据
      TOtableData: [], //TO小b数据
      othertableData: [],// 其他
      allNum: Number(0),
      allTotal: Number(0),
      allNum2: Number(0),
      allTotal2: Number(0),
      allNum3: Number(0),
      allTotal3: Number(0),
      allNum4: Number(0),
      allTotal4: Number(0),
      allNum5: Number(0),
      allTotal5: Number(0),
      allNum6: Number(0),
      allTotal6: Number(0),
      fileList: [],
      fileIds: [],
      delFileId:[], // 删除的图片id
      previewVisible: false,
      previewImage: "",
      marketCenterList: [], // 营销中心数据
      marketCenterId: "", //营销中心id
      marketCenter: "",
      officeList: [],// 办事处数据
      officeName: "",// 办事处
      officeId: "", // 办事处id
      timeStart: "", // 活动时间
      statusList: [], // 审批状态数据
      statusName: "", //审批状态

      FXDLlnum: "", // 模糊搜索 代理商id
      FXDLlist: [], // 模糊搜索出来的数据
      FXList: [],
      DLMDList:[],
      FXName: [],
      processInstId: "",
      FXID: "",
      DLMDId:'',
      DLMDName:[],//参与门店
      therm: "",// 主题
      address: "",// 地址
      activityAttributeId: "",// 活动属性
      isAskRes: "", // 是否申请总部资源
      activityAttributeList: [],// 活动属性数据
      targetAmount: "", // 目标金额
      // totalInvestment: "", // 投入合计
      advertisement: "",// 待制作广告
      activityCode: "",// 活动编码
      visible: false,
      tableDataOne: [],
      tableDataTwo: [],
      tableDataThree: [],
      tableDatafour: [],
      tableDatafive: [],
      tableDatasix: [],
      tableDataCANYU:[],// 参与品类
      deletArr: [],
      columns1: [
        {
          title: "物料组",
          dataIndex: "matklName",
          width: 200,
          scopedSlots: { customRender: 'type' },
        },
        // {
        //   title: "计划投入分摊金额：元",
        //   dataIndex: "applyCost",
        //   width: 200,
        //   scopedSlots: { customRender: 'applyCost' },
        // },
        {
          title: "项目目标(万元)",
          dataIndex: "activityTargetDetail",
          width: 200,
          scopedSlots: { customRender: 'activityTargetDetail' },
        },

      ],
      tableData1:[],
      MarketTableList:[
        {
          id: 20160119008,
          matklName: '平板电视'
        },
        {
          id: 20160119013,
          matklName: '激光电视'
        },
        {
          id: 20160119014,
          matklName: '海信空调'
        },
        // {
        //   id: 20160119015,
        //   matklName: '科龙空调'
        // },
        {
          id: 20160119018,
          matklName: '海信冰箱'
        },
        {
          id: 20160119019,
          matklName: '容声冰箱'
        },
        {
          id: 20160119020,
          matklName: '洗衣机'
        },
        {
          id: 20160119021,
          matklName: '容声洗衣机'
        },
        {
          id: 20160119022,
          matklName: '海信冷柜'
        },
        {
          id: 20160119023,
          matklName: '容声冷柜'
        },
        
      ],
      ActivityEndTime:'',// 活动结束时间
      ActivityStartTime:'',// 活动开始时间
    };
  },
  watch: {
    tableData1: {
      handler(newval) {
        let activityTargetDetail_ = 0;
      for (const item of newval) {
        activityTargetDetail_ += item.activityTargetDetail || 0
      }
      this.targetAmount = activityTargetDetail_ ? activityTargetDetail_ : '';
      },
      // immediate: true,
      deep: true
    },
    // 'tableData':function(newval,val) {
      // this.allNum = 0
      // this.allTotal = 0
      // if(newval.length > 0) {
      //   newval.forEach(item => {
      //     item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
      //     this.allNum = this.accAdd( this.allNum,Number(this.transNum(item.num)))
      //     this.allTotal = this.accAdd( this.allTotal,Number(this.transNum(item.total))).toFixed(2)
      //   });
      // } else {
      //   this.allNum  = 0;
      //   this.allTotal = 0
      // }
    // },
    // 'MttableData':function(newval,val) {
    //   this.allNum2 = 0
    //   this.allTotal2 = 0
    //   if (newval.length > 0) {
    //     newval.forEach(item => {
    //       item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
    //       this.allNum2 = this.accAdd( this.allNum2,Number(this.transNum(item.num)))
    //       this.allTotal2 = this.accAdd( this.allTotal2,Number(this.transNum(item.total))).toFixed(2)
    //     });
    //   }else {
    //     this.allNum2  = 0;
    //     this.allTotal2 = 0
    //   }
    // },
    // 'lctableData':function(newval,val) {
    //   this.allNum3 = 0
    //   this.allTotal3 = 0
    //   if (newval.length > 0) {
    //     newval.forEach(item => {
    //       item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
    //       this.allNum3 = this.accAdd( this.allNum3,Number(this.transNum(item.num)))
    //       this.allTotal3 = this.accAdd( this.allTotal3,Number(this.transNum(item.total))).toFixed(2)
    //     });
    //   }else {
    //     this.allNum3  = 0;
    //     this.allTotal3 = 0
    //   }
    // },
    // 'zptableData':function(newval,val) {
    //   this.allNum4 = 0
    //   this.allTotal4 = 0
    //   if (newval.length > 0) {
    //     newval.forEach(item => {
    //       item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
    //       this.allNum4 = this.accAdd( this.allNum4,Number(this.transNum(item.num)))
    //       this.allTotal4 = this.accAdd( this.allTotal4,Number(this.transNum(item.total))).toFixed(2)
    //     });
    //   }else {
    //     this.allNum4  = 0;
    //     this.allTotal4 = 0
    //   }
    // },
    // 'TOtableData':function(newval,val) {
    //   this.allNum5 = 0
    //   this.allTotal5 = 0
    //   if (newval.length > 0) {
    //     newval.forEach(item => {
    //       item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
    //       this.allNum5 = this.accAdd( this.allNum5,Number(this.transNum(item.num)))
    //       this.allTotal5 = this.accAdd( this.allTotal5,Number(this.transNum(item.total))).toFixed(2)
    //     });
    //   }else {
    //     this.allNum5  = 0;
    //     this.allTotal5 = 0
    //   }
    // },
    // 'othertableData':function(newval,val) {
    //   this.allNum6 = 0
    //   this.allTotal6 = 0
    //   if (newval.length > 0) {
    //     newval.forEach(item => {
    //       item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
    //       this.allNum6 = this.accAdd( this.allNum6,Number(this.transNum(item.num)))
    //       this.allTotal6 = this.accAdd( this.allTotal6,Number(this.transNum(item.total))).toFixed(2)
    //     });
    //   }else {
    //     this.allNum6  = 0;
    //     this.allTotal6 = 0
    //   }
    // },
  },
  mounted() {
    this.getYYXT();
    this.getActivityAttributeList();//获取活动属性
    this.type = this.$route.query.type;
    if (this.type == "add") {
      // this.ActivityCode();
    }
    if (this.type == "edit") {
      this.getSignleInfo(this.$route.query.id);
    }
    if (this.type == "edit") {
      this.breadcrumbData[2].title = "活动编辑";
    }
  },
  computed: {
    // 各项投入合计(元)
    totalInvestment() {
      const tab2Total = this.tableData.reduce((sum, item) => {
        return sum + (item.total || 0);
      }, 0);
      const tab3Total = this.MttableData.reduce((sum, item) => {
        return sum + (item.total || 0);
      }, 0);
      const tab4Total = this.lctableData.reduce((sum, item) => {
        return sum + (item.total || 0);
      }, 0);
      const tab5Total = this.zptableData.reduce((sum, item) => {
        return sum + (item.total || 0);
      }, 0);
      const tab6Total = this.TOtableData.reduce((sum, item) => {
        return sum + (item.total || 0);
      }, 0);
      const tab7Total = this.othertableData.reduce((sum, item) => {
        return sum + (item.total || 0);
      }, 0);
      if(tab2Total + tab3Total + tab4Total + tab5Total + tab6Total + tab7Total){
        return (tab2Total + tab3Total + tab4Total + tab5Total + tab6Total + tab7Total).toFixed(2) || ''
      }else{
        return ''
      }
      
    },

  },
  methods: {
    // 参与品类所需物料组
    getMarketList(id) {
      this.MarketTableList = []
      let data  = {
        custId:id
      }
      getMatklList(data).then(res=>{
        this.MarketTableList = res.data
      })
    },
    // 更改营销
    handleChangeshopList2(item,index) {
      //  更改选项的时候判断当前数据和接口数据的值是否一致 赋值新的id
      this.MarketTableList.forEach(marItem => {
        this.tableData1.forEach(item => {
          if (marItem.matklName == item.matklName) {
            item.matklId = marItem.id
          }
        })
      })
    },
    onSelectChangecypl(selectedRowKeys) {
      this.selectedRowKeyscypl = selectedRowKeys;
    },
    // 添加行
    addlineNew() {
      if (this.type == "add") {
        if (!this.FXDLlnum) {
          this.$message.warning('请先选择活动承接运营(代理)商')
          return
        }
      } else {
        if (!this.dlId) {
          this.$message.warning('请先选择活动承接运营(代理)商')
          return
        }
      }
      this.tableData1.push(
        {
          matklName: "",
          matklId: "",
          // applyCost: 0, //计划分摊金额
          activityTargetDetail: 0, //项目目标(万元)
        }
      )
    },
    // 复制行数据
    copyNew() {
      if (this.selectedRowKeyscypl.length <= 0) {
        this.$message.warning('请至少选择一项数据!')
      } else if (this.selectedRowKeyscypl.length > 1) {
        this.$message.warning('只能一条数据进行复制!')
      } else {
        let aa = {}
        this.tableData1.forEach((item, index) => {
          this.selectedRowKeyscypl.forEach(i => {
            if (index == i) {
              aa = {
                matklId:  this.tableData1[index].matklId,
                matklName:  this.tableData1[index].matklName,
              }
            }
          })
        })
        this.tableData1.push(aa)
      }
    },

    //删除行数据
    delLineNew() {
      if (this.selectedRowKeyscypl.length <= 0) {
        this.$message.warning('请至少选择一项数据')
      } else {
        this.tableData1 = this.tableData1.filter((item, index) => !this.selectedRowKeyscypl.includes(index))
        this.selectedRowKeyscypl = []
      }
    },
    // 格式化数量 只能输入大于等于0的数字
   transformNum(value){
      return value.toString().replace(/[^\d]/g,'')
    },
    transformPrice(value){
      return value.toString().replace(/[^\d.]/g, "")
    },
    // 数据相乘精度
    accMul(arg1, arg2) {
      var m = 0,
        s1 = arg1.toString().replace(/[^\d.]/g,''),
        s2 = arg2.toString().replace(/[^\d.]/g,'');
      try {
        m += (s1.split(".")[1]?s1.split(".")[1].length:'')
      } catch (e) {
        console.log(e);
      }
      try {
        m += (s2.split(".")[1]?s2.split(".")[1].length:'')
      } catch (e) {
        console.log(e);
      }

      return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
    },
    // 解决计算失去精度的方法
    accAdd(arg1, arg2) {
      const arg1_ = arg1.toString().replace(/[^\d.]/g, "");
      const arg2_ = arg2.toString().replace(/[^\d.]/g, "");
      var r1, r2, m;
      try {
        r1 = arg1_ && arg1_.toString().split(".")[1]?arg1_.toString().split(".")[1].length:''
      } catch (e) {
        r1 = 0
      }
      try {
        r2 = arg2_ && arg2_.toString().split(".")[1]?arg2_.toString().split(".")[1].length:''
      } catch (e) {
        r2 = 0
      }
      m = Math.pow(10, Math.max(r1, r2))
      return (arg1_ * m + arg2_ * m) / m
    },
    changeThem(e) {
      if (e.target.value.length >= 40) {
        this.$message.warning("最多输入40个文字");
        return false;
      }
    },
    changeplace(e) {
      if (e.target.value.length >= 40) {
        this.$message.warning("最多输入40个文字");
        return false;
      }
    },
    changeguanggao(e) {
      if (e.target.value.length >= 40) {
        this.$message.warning("最多输入40个文字");
        return false;
      }
    },

    // 全选 -- 把数据源赋给select绑定的值
    selectAll() {
      let arr = [];
      this.FXList.forEach((item) => {
        arr.push(item.code);
      });
      this.FXName = arr;
    },
    // 清空 -- 清空select绑定的值
    clearAll() {
      this.FXName = [];
    },

    selectAllDLMD() {
      let arr = [];
      this.DLMDList.forEach((item) => {
        arr.push(item.code);
      });
      this.DLMDName = arr;
    },
    // 清空 -- 清空select绑定的值
    clearAllDLMD() {
      this.DLMDName = [];
    },
    goback() {
      window.close();
      this.$router.push({ path: "/agentVerificationActivities" });
    },
    changeDl(value, e) {
      this.dlId = value;
      this.FXDLlnum = value
    },
    // 如果是编辑页面获取单条数据
    getSignleInfo(id) {
      let data = {
        id: id
      };
      getActivityInfo(data).then(res => {
        this.marketCenterId = res.data.data.marketCenterId;
        // 传获取到的数据循环判断得出 营销中心code 为了获取办事处数据
        let marketCode = "";
        this.marketCenterList.forEach(item => {
          if (item.id == this.marketCenterId) {
            marketCode = item.code;
          }
        });
        this.getOffice(marketCode);
        this.officeName = res.data.data.orgId;
        this.officeId = res.data.data.orgId;
        this.timeStart = res.data.data.applyMonth;
        this.FXDLlnum = res.data.data.custInfoId_name;
        this.dlId = res.data.data.custInfoId;
        this.therm = res.data.data.activityTheme;
        this.address = res.data.data.activityPlace;
        this.activityAttributeId = res.data.data.activityAttributeId;
        this.isAskRes = res.data.data.isAskRes;
        this.targetAmount = res.data.data.activityTarget;
        this.activityCode = res.data.data.activityCode;
        // 活动开始时间
        this.ActivityStartTime = res.data.data.applyStartTime;
        // 活动结束时间
        this.ActivityEndTime = res.data.data.applyEndTime;
        // this. FXName = res.data.data.fxCustName
        // this.getMarketList(this.dlId)
        this.getFx(this.dlId);
        this.getDL(this.dlId)
        if (res.data.data.fxCust && res.data.data.fxCust.length>0&& res.data.data.fxCust[0]) {
          this.FXName = res.data.data.fxCust[0].split(",");
        } else {
          this.FXName = [];
        }
        if (res.data.data.cyShop && res.data.data.cyShop.length>0&& res.data.data.cyShop[0]) {
          this.DLMDName = res.data.data.cyShop[0].split(",");
        } else {
          this.DLMDName = [];
        }
        this.processInstId = res.data.data.processInstId;
        this.FXId = res.data.data.fxCust&& res.data.data.fxCust.length>0? res.data.data.fxCust[0].split(","):'';
        this.DLMDId = res.data.data.cyShop&&res.data.data.cyShop.length>0 ?res.data.data.cyShop[0].split(","):'';
        this.totalInvestment = res.data.data.totalMoney;
        this.advertisement = res.data.data.adCompany;
        this.tableData = res.data.data.matklDtoList;
        // 最后需要对比的数据
        this.tableDataOne = res.data.data.matklDtoList;

        this.MttableData = res.data.data.mediaDtoList;
        // 最后需要对比的数据
        this.tableDataTwo = res.data.data.mediaDtoList;

        this.zptableData = res.data.data.giftDtoList;
        // 最后需要对比的数据
        this.tableDatafour = res.data.data.giftDtoList;

        this.lctableData = res.data.data.tempDtoList;
        // 最后需要对比的数据
        this.tableDataThree = res.data.data.tempDtoList;

        this.TOtableData = res.data.data.tobDtoList;
        // 最后需要对比的数据
        this.tableDatafive = res.data.data.tobDtoList;

        this.othertableData = res.data.data.otherDtoList;
        // 最后需要对比的数据
        this.tableDatasix = res.data.data.otherDtoList;
        // 参与品类
        this.tableData1 = res.data.data.productLineDtoList || [];
        this.tableDataCANYU = res.data.data.productLineDtoList || [];

        let fff = [];
        if (res.data.data.attachs) {
          res.data.data.attachs.forEach(item => {
            fff.push({
              id: (item.id).toString()
            });
          });
        }
        this.fileIds = fff;
        let aaa = [];
        if (res.data.data.attachs) {
          res.data.data.attachs.forEach(item => {
            aaa.push(
              {
                name:item.attachName,
                uid: item.id,
                name2: "旧的",
                status: "done",
                url: item.attachPath
              }
            );
          });
        }
        this.fileList = aaa;
        if (this.TOtableData.length > 0) {
          this.TOtableData.forEach(item => {
            item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
            this.allNum5 = this.accAdd( this.allNum5,Number(this.transNum(item.num)))
            this.allTotal5 = this.accAdd( this.allTotal5,Number(this.transNum(item.total))).toFixed(2)
          });
        }
        if (this.othertableData.length > 0) {
          this.othertableData.forEach(item => {
            item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
            this.allNum6 = this.accAdd( this.allNum6,Number(this.transNum(item.num)))
            this.allTotal6 = this.accAdd( this.allTotal6,Number(this.transNum(item.total))).toFixed(2)
          });
        }
        if (this.tableData.length > 0) {
          this.tableData.forEach(item => {
            item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
            this.allNum = this.accAdd( this.allNum,Number(this.transNum(item.num)))
            this.allTotal = this.accAdd( this.allTotal,Number(this.transNum(item.total))).toFixed(2)
          });
        }

        if (this.MttableData.length > 0) {
          this.MttableData.forEach(item => {
            item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
            this.allNum2 = this.accAdd( this.allNum2,Number(this.transNum(item.num)))
            this.allTotal2 = this.accAdd( this.allTotal2,Number(this.transNum(item.total))).toFixed(2)
          });
        }
        if (this.lctableData.length > 0) {
          this.lctableData.forEach(item => {
            item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
            this.allNum3 = this.accAdd( this.allNum3,Number(this.transNum(item.num)))
            this.allTotal3 = this.accAdd( this.allTotal3,Number(this.transNum(item.total))).toFixed(2)
          });
        }
        if (this.zptableData.length > 0) {
          this.zptableData.forEach(item => {
            item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
            this.allNum4 = this.accAdd( this.allNum4,Number(this.transNum(item.num)))
            this.allTotal4 = this.accAdd( this.allTotal4,Number(this.transNum(item.total))).toFixed(2)
          });
        }
      });
    },
    getReason($event, value) {
      this.bscManager = value;
      this.content = $event;
      this.visible = false;
      this.pageLoadFlag = true;
      this.confim();

    },
    handleChangeFXList(value, e) {
      this.FXId = value;
    },
    // 修改参与门店
    handleChangeDLList(value, e) {
      this.DLMDId = value;
    },
    // 输入模糊搜索代理商
    getDLList(value) {
      this.FXDLlnum = value;
      let data = {
        searchStr: this.FXDLlnum
      };
      this.fetching = true;
      getDLlnumSeach(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
          this.fetching = false;
        } else {
          data = res.data;
        }
        this.fetching = false;
        this.FXDLlist = [...new Set(data.data)]; //缓存列表数据，前端分页
      });
    },
    // 输入模糊搜索待制作广告
    getGGList(value) {
      let data = {
        searchstr: value
      };
      this.fetching = true;
      getSupplierNameByOrg(data).then(res => {
        this.fetching = false;
        this.GGlist = res.data;
      });
    },
    //查询数据
    search() {
      debugger
      this.getFx(this.FXDLlnum);
      // 查询参与品类的下拉项
      // this.getMarketList(this.FXDLlnum)
      this.getDL(this.FXDLlnum)
    },
    // 获取分销商
    getFx(id) {
      let data = {
        agentId: id
      };
      getFXlnumSeach (data).then(res => {
        this.FXList = res.data.data;
      });
    },
    getDL(id) {
      let data = {
        agentId: id
      };
      getDLMDlnumSeach(data).then(res => {
        this.DLMDList = res.data.data;
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 时间更改
    handleTimeChange(e, defaultTime) {
      this.timeStart = defaultTime;
    },

    getDateAfterYears(timestamp, years) {
      const date = new Date(timestamp);
      date.setFullYear(date.getFullYear() + years);
      return date.getTime();
    },
    fun_date1() {
      var _this = this;
      let yy = new Date().getFullYear();
      let mm =  (new Date().getMonth()+ 1) < 10 ? "0" + (new Date().getMonth()+ 1) : new Date().getMonth()+ 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds() < 10 ? "0" + new Date().getSeconds() : new Date().getSeconds();
      return yy + "-" + mm+ "-" +dd;
    },
    // 修改活动结束时间
    handleTimeEndChange(e, defaultTime) {
      //代理商活动结束时间不允许超过当前时间一年的时间，选择结束时间大于一年时，清空选择的时间并
      // 提示：根据活动管理与规划要求，活动结束时间不能超过一年，请您重新选择活动规划时间或延后提交申请
      this.ActivityEndTime = defaultTime;
      // 获取当前年月日 转换时间戳
      let nowTime =  new Date(this. fun_date1()).getTime()
      // 获取下一年的时间戳
      const nextYearTimestamp = this.getDateAfterYears(nowTime, 1); // 下一年的时间戳
      // 获取当前选择的时间的时间戳
      let selectTimeSamp = new Date(this.ActivityEndTime).getTime()
      // 选择时间和一年后时间戳对比 选择时间超过一年弹出提示
      if(selectTimeSamp>nextYearTimestamp){
        this.$message.warning('根据活动管理与规划要求，活动结束时间不能超过一年，请您重新选择活动规划时间或延后提交申请')
        this.ActivityEndTime = ''
        return
      }
      // if( this.ActivityStartTime && ( new Date(this.ActivityStartTime).getTime() > new Date(this.ActivityEndTime).getTime()) ) {
      //   this.$message.warning('结束时间不能小于开始时间')
      //   this.ActivityEndTime = ''
      //   return
      // }
    },
    handleTimeStartChange(e, defaultTime) {
      this.ActivityStartTime = defaultTime;
    if( this.ActivityEndTime &&( new Date(this.ActivityEndTime).getTime() < new Date(this.ActivityStartTime).getTime()) ) {
      this.$message.warning('开始时间不能大于结束时间')
      this.ActivityStartTime = ''
      return
    }
    },

    //获取活动属性数据
    getActivityAttributeList() {
      getActivityAttribute().then(res => {
        if(res.data && res.data.length > 0){
          this.activityAttributeList = res.data.map(j=>{
            return{
              ...j,
              code: Number(j.code)
            }
          })
        }
      });
    },
    //获取营销zhongxin数据
    getYYXT() {
      let data = {};
      getYxList(data).then(res => {
        this.marketCenterList = res.data.data;
        if( this.marketCenterList.length > 0 ) {
          this.marketCenterId = res.data.data[0].id;
          this.marketCenter  = res.data.data[0].code;
          this.getOffice(this.marketCenter)
        }
      });
    },
    // 更改营销
    handleChangeshopList(value, e) {
      this.marketCenterId = value;
      this.marketCenter = e.key;
      this.officeList = [];
      this.officeName = "";
      this.officeId = "";
      this.getOffice(this.marketCenter);
    },
    // 根据营销中心获取办事处数据
    getOffice(id) {
      let data = {
        orgCode: id
      };
      getofficeList(data).then(res => {
        this.officeList = res.data.data;
        if(this.officeList.length>0){
          this.officeName = res.data.data[0].name;
          this.officeId = res.data.data[0].id;
        }
      });
    },
    handleChangeofficeList(value, e) {
      this.officeName = value;
      this.officeId = e.key;
    },

    // 新增活动代码或者编辑活动代码或者核销活动代码
    // 复制行数据
    copy(num) {
      if (num == "1") {
        if (this.selectedRowKeys.length > 1) {
          this.$message.warning("只能一条数据进行复制!");
        } else {
          let aa = {};
          this.tableData.forEach((item, index) => {
            this.selectedRowKeys.forEach(i => {
              if (index == i) {
                aa = {
                  type: this.tableData[index].type,
                  num: this.tableData[index].num,
                  price: this.tableData[index].price,
                  total: this.tableData[index].total,
                  remark: this.tableData[index].remark
                };
              }
            });
          });
          this.tableData.push(aa);
        }
      } else if (num == "2") {
        if (this.MTselectedRowKeys.length > 1) {
          this.$message.warning("只能一条数据进行复制!");
        } else {
          let aa = {};
          this.MttableData.forEach((item, index) => {
            this.MTselectedRowKeys.forEach(i => {
              if (index == i) {
                // aa = this.MttableData[index]
                aa = {
                  type: this.MttableData[index].type,
                  num: this.MttableData[index].num,
                  price: this.MttableData[index].price,
                  total: this.MttableData[index].total,
                  remark: this.MttableData[index].remark
                };
              }
            });
          });
          this.MttableData.push(aa);
        }
      } else if (num == "3") {
        if (this.lcselectedRowKeys.length > 1) {
          this.$message.warning("只能一条数据进行复制!");
        } else {
          let aa = {};
          this.lctableData.forEach((item, index) => {
            this.lcselectedRowKeys.forEach(i => {
              if (index == i) {
                // aa = this.lctableData[index]
                aa = {
                  type: this.lctableData[index].type,
                  num: this.lctableData[index].num,
                  price: this.lctableData[index].price,
                  total: this.lctableData[index].total,
                  remark: this.lctableData[index].remark
                };
              }
            });
          });
          this.lctableData.push(aa);
        }

      } else if (num == "4") {
        if (this.zpselectedRowKeys.length > 1) {
          this.$message.warning("只能一条数据进行复制!");
        } else {
          let aa = {};
          this.zptableData.forEach((item, index) => {
            this.zpselectedRowKeys.forEach(i => {
              if (index == i) {
                // aa = this.zptableData[index]
                aa = {
                  type: this.zptableData[index].type,
                  num: this.zptableData[index].num,
                  price: this.zptableData[index].price,
                  total: this.zptableData[index].total,
                  remark: this.zptableData[index].remark
                };
              }
            });
          });
          this.zptableData.push(aa);
        }
      } else if (num == "5") {
        if (this.TOselectedRowKeys.length > 1) {
          this.$message.warning("只能一条数据进行复制!");
        } else {
          let aa = {};
          this.TOtableData.forEach((item, index) => {
            this.TOselectedRowKeys.forEach(i => {
              if (index == i) {
                // aa = this.TOtableData[index]
                aa = {
                  type: this.TOtableData[index].type,
                  num: this.TOtableData[index].num,
                  price: this.TOtableData[index].price,
                  total: this.TOtableData[index].total,
                  remark: this.TOtableData[index].remark
                };
              }
            });
          });
          this.TOtableData.push(aa);
        }
      } else if (num == "6") {
        if (this.otherselectedRowKeys.length > 1) {
          this.$message.warning("只能一条数据进行复制!");
        } else {
          let aa = {};
          this.othertableData.forEach((item, index) => {
            this.otherselectedRowKeys.forEach(i => {
              if (index == i) {
                // aa = this.othertableData[index]
                aa = {
                  type: this.othertableData[index].type,
                  num: this.othertableData[index].num,
                  price: this.othertableData[index].price,
                  total: this.othertableData[index].total,
                  remark: this.othertableData[index].remark
                };
              }
            });
          });
          this.othertableData.push(aa);
        }
      }
    },

    //删除行数据
    delLine(num) {
      if (num == "one") {
        if (this.selectedRowKeys.length <= 0) {
          this.$message.warning("请至少选择一项");
        } else {
          this.tableData = this.tableData.filter((item, index) => !this.selectedRowKeys.includes(index));
          this.selectedRowKeys = [];
        }
      }
      if (num == "two") {
        if (this.MTselectedRowKeys.length <= 0) {
          this.$message.warning("请至少选择一项");
        } else {
          this.MttableData = this.MttableData.filter((item, index) => !this.MTselectedRowKeys.includes(index));
          this.MTselectedRowKeys = [];
        }
      }
      if (num == "three") {
        if (this.lcselectedRowKeys.length <= 0) {
          this.$message.warning("请至少选择一项");
        } else {
          this.lctableData = this.lctableData.filter((item, index) => !this.lcselectedRowKeys.includes(index));
          this.lcselectedRowKeys = [];
        }
      }

      if (num == "four") {
        if (this.zpselectedRowKeys.length <= 0) {
          this.$message.warning("请至少选择一项");
        } else {
          this.zptableData = this.zptableData.filter((item, index) => !this.zpselectedRowKeys.includes(index));
          this.zpselectedRowKeys = [];
        }
      }
      if (num == "five") {
        if (this.TOselectedRowKeys.length <= 0) {
          this.$message.warning("请至少选择一项");
        } else {
          this.TOtableData = this.TOtableData.filter((item, index) => !this.TOselectedRowKeys.includes(index));
          this.TOselectedRowKeys = [];
        }
      }
      if (num == "six") {
        if (this.otherselectedRowKeys.length <= 0) {
          this.$message.warning("请至少选择一项");
        } else {
          this.othertableData = this.othertableData.filter((item, index) => !this.otherselectedRowKeys.includes(index));
          this.otherselectedRowKeys = [];
        }
      }

    },
    // 勾选数据
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    MTonSelectChange(selectedRowKeys) {
      this.MTselectedRowKeys = selectedRowKeys;
    },
    LConSelectChange(selectedRowKeys) {
      this.lcselectedRowKeys = selectedRowKeys;
    },
    ZPonSelectChange(selectedRowKeys) {
      this.zpselectedRowKeys = selectedRowKeys;
    },
    TOonSelectChange(selectedRowKeys) {
      this.TOselectedRowKeys = selectedRowKeys;
    },
    otheronSelectChange(selectedRowKeys) {
      this.otherselectedRowKeys = selectedRowKeys;
    },
    // 添加行
    addline(num) {
      if (num == "one") {
        this.tableData.push(
          {
            type: "",
            num: 0,
            price: 0,
            total: 0,
            remark: ""

          }
        );
      }
      if (num == "two") {
        this.MttableData.push(
          {
            type: "",
            num: 0,
            price: 0,
            total: 0,
            remark: ""

          }
        );
      }
      if (num == "three") {
        this.lctableData.push(
          {
            type: "",
            num: 0,
            price: 0,
            total: 0,
            remark: ""
          }
        );
      }
      if (num == "four") {
        this.zptableData.push(
          {
            type: "",
            num: 0,
            price: 0,
            total: 0,
            remark: ""

          }
        );
      }
      if (num == "five") {
        this.TOtableData.push(
          {
            type: "",
            num: 0,
            price: 0,
            total: 0,
            remark: ""

          }
        );
      }
      if (num == "six") {
        this.othertableData.push(
          {
            type: "",
            num: 0,
            price: 0,
            total: 0,
            remark: ""

          }
        );
      }


    },
    transNum(n){
      return n.toString().replace(/[^\d.]/g, "")
    },
    // 更改数量
    changeNum(num) {
      if (num == "one") {
        this.allNum = 0;
        this.allTotal = 0;
        // 转化数字 防止输入字母
        // var transNum = (n)=>{
        //   return n.toString().replace(/[^\d.]/g, "")
        // }
        this.tableData.forEach(item => {
          // item.total = item.num * item.price;
          // this.allNum += Number(item.num);
          // this.allTotal += Number(item.total);
          item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
          this.allNum = this.accAdd( this.allNum,Number(this.transNum(item.num)))
          this.allTotal = this.accAdd( this.allTotal,Number(this.transNum(item.total))).toFixed(2)
          // item.total = this.accMul(transNum(item.num),transNum(item.price))
          // this.allNum = this.accAdd( transNum(this.allNum),transNum(Number(item.num)))
          // this.allTotal = this.accAdd( transNum(this.allTotal),transNum(Number(item.total)))
        });
      }
      if (num == "two") {
        this.allNum2 = 0;
        this.allTotal2 = 0;
        this.MttableData.forEach(item => {
          // item.total = item.num * item.price;
          // this.allNum2 += Number(item.num);
          // this.allTotal2 += Number(item.total);
          item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
          this.allNum2 = this.accAdd( this.allNum2,Number(this.transNum(item.num)))
          this.allTotal2 = this.accAdd( this.allTotal2,Number(this.transNum(item.total))).toFixed(2)
        });
      }
      if (num == "three") {
        this.allNum3 = 0;
        this.allTotal3 = 0;
        this.lctableData.forEach(item => {
          // item.total = item.num * item.price;
          // this.allNum3 += Number(item.num);
          // this.allTotal3 += Number(item.total);
          item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
          this.allNum3 = this.accAdd( this.allNum3,Number(this.transNum(item.num)))
          this.allTotal3 = this.accAdd( this.allTotal3,Number(this.transNum(item.total))).toFixed(2)
        });
      }
      if (num == "four") {
        this.allNum4 = 0;
        this.allTotal4 = 0;
        this.zptableData.forEach(item => {
          item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
          this.allNum4 = this.accAdd( this.allNum4,Number(this.transNum(item.num)))
          this.allTotal4 = this.accAdd( this.allTotal4,Number(this.transNum(item.total))).toFixed(2)
        });
      }
      if (num == "five") {
        this.allNum5 = 0;
        this.allTotal5 = 0;
        this.TOtableData.forEach(item => {
          item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
          this.allNum5 = this.accAdd( this.allNum5,Number(this.transNum(item.num)))
          this.allTotal5 = this.accAdd( this.allTotal5,Number(this.transNum(item.total))).toFixed(2)
        });
      }
      if (num == "six") {
        this.allNum6 = 0;
        this.allTotal6 = 0;
        this.othertableData.forEach(item => {
          item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
          this.allNum6 = this.accAdd( this.allNum6,Number(this.transNum(item.num)))
          this.allTotal6 = this.accAdd( this.allTotal6,Number(this.transNum(item.total))).toFixed(2)
        });
      }
    },
    // 更改价格
    changePrice(num) {
      if (num == "one") {
        this.allNum = 0;
        this.allTotal = 0;
        this.tableData.forEach(item => {
          item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
          this.allNum = this.accAdd( this.allNum,Number(this.transNum(item.num)))
          this.allTotal = this.accAdd( this.allTotal,Number(this.transNum(item.total))).toFixed(2)
        });
      }
      if (num == "two") {
        this.allNum2 = 0;
        this.allTotal2 = 0;
        this.MttableData.forEach(item => {
          item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
          this.allNum2 = this.accAdd( this.allNum2,Number(this.transNum(item.num)))
          this.allTotal2 = this.accAdd( this.allTotal2,Number(this.transNum(item.total))).toFixed(2)
        });
      }
      if (num == "three") {
        this.allNum3 = 0;
        this.allTotal3 = 0;
        this.lctableData.forEach(item => {
          item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
          this.allNum3 = this.accAdd( this.allNum3,Number(this.transNum(item.num)))
          this.allTotal3 = this.accAdd( this.allTotal3,Number(this.transNum(item.total))).toFixed(2)
        });
      }
      if (num == "four") {
        this.allNum4 = 0;
        this.allTotal4 = 0;
        this.zptableData.forEach(item => {
          item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
          this.allNum4 = this.accAdd( this.allNum4,Number(this.transNum(item.num)))
          this.allTotal4 = this.accAdd( this.allTotal4,Number(this.transNum(item.total))).toFixed(2)
        });
      }
      if (num == "five") {
        this.allNum5 = 0;
        this.allTotal5 = 0;
        this.TOtableData.forEach(item => {
          item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
          this.allNum5 = this.accAdd( this.allNum5,Number(this.transNum(item.num)))
          this.allTotal5 = this.accAdd( this.allTotal5,Number(this.transNum(item.total))).toFixed(2)
        });
      }
      if (num == "six") {
        this.allNum6 = 0;
        this.allTotal6 = 0;
        this.othertableData.forEach(item => {
          item.total = this.accMul(this.transNum(item.num),this.transNum(item.price))
          this.allNum6 = this.accAdd( this.allNum6,Number(this.transNum(item.num)))
          this.allTotal6 = this.accAdd( this.allTotal6,Number(this.transNum(item.total))).toFixed(2)
        });
      }
    },

    beforeUpload(file) {
      // 不可大于50M
      const size = (file.size / 1024 / 1024) <= 50;
      if (!size) {
        this.$message.warning('单个文件上传不能超过50M！');
        return
      }
      this.pageLoadFlag = true
      // this.fileList = [...this.fileList, file];
      const formData = new FormData();
      formData.append("file", file);
      // 上传接口/custAgentActivity/uploadFile.nd
      let config = {
        headers: { "content-type": "multipart/form-data" }
      };
      // 发送请求
      const BASE_API = publicPath;
      axios.post(BASE_API + "/custAgentActivity/uploadFile.nd", formData, config).then(res => {
        this.pageLoadFlag = false
        if (res.data.message == "上传成功") {
          this.fileList = [...this.fileList, file];

          this.fileIds.push({
            id: res.data.businessId
          });
        } else {
          this.$message.warning("上传失败", res.data.message);
        }
      }).catch(error=>{
        this.$message.warning('上传失败'+error)
        this.pageLoadFlag = false
      });
      return false;
    },
    handleChange({ fileList }) {
      let fileList1 = [...this.fileList];
      this.fileList = fileList1;
    },
    handleRemove(file,item) {
      const index = file;
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
      this.fileIds.splice(index, 1);
      if(this.type == "edit" ) {
        // 增加一个标识  只有是之前的数据删除掉才push
        if(item.name2 == '旧的') {
          this.delFileId.push({
            id: item.uid
          });
        }
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (file.url) {
        window.open(file.url);
      } else {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        this.previewImage = file.url || file.preview;
        this.previewVisible = true;
      }
    },
    handleDownload(item) {
      window.open(item.url, '_blank')
    },
    // 上传附件代码结束
    openWindow() {
      if(!this.isAskRes){
        return this.$message.warning("请选择是否申请总部资源");
      }
      const this_ = this;
      let flagArr = [true, true]
      if (Number(this.targetAmount) > 1000) {
        flagArr[0] = false;
        this.$confirm({
          title: '提示',
          content: '活动目标已超1000万，请核实并确认',
          onOk() {
            if (Number(this_.targetAmount) * 10000 <= Number(this_.totalInvestment)) {
              this_.$message.warning("项目目标总和应当大于各项投入合计，单位为：元，请确认填写是否无误");
              return false;
            }
            flagArr[0] = true;
            // 确认完成
            if(flagArr.every(j=>j)){
              this_.visible = true;
            }
          },
          onCancel() {
            return
          },
        });
      }
      if (Number(this_.totalInvestment) > 10000) {
        flagArr[1] = false;
        this.$confirm({
          title: '提示',
          content: '活动投入已超10000元，请核实并确认',
          onOk() {
            if (Number(this_.targetAmount) * 10000 <= Number(this_.totalInvestment)) {
              this_.$message.warning("项目目标总和应当大于各项投入合计，单位为：元，请确认填写是否无误");
              return false;
            }
            flagArr[1] = true;
            // 确认完成
            if(flagArr.every(j=>j)){
              this_.visible = true;
            }
          },
          onCancel() {
            return
          },
        });
      }

      if(flagArr.every(j=>j)){
        this_.visible = true;
      }
      
      
    },
    // 获取删除的数据
    getDeletInfo() {

      // 物料
      let A = this.tableData.map(x => x.id);
      let newList = this.tableDataOne.filter(item => !A.includes(item.id));
      // 媒体
      let b = this.MttableData.map(x => x.id);
      let newList2 = this.tableDataTwo.filter(item => !b.includes(item.id));
      //临促
      let c = this.lctableData.map(x => x.id);
      let newList3 = this.tableDataThree.filter(item => !c.includes(item.id));
      //赠品
      let d = this.zptableData.map(x => x.id);
      let newList4 = this.tableDatafour.filter(item => !d.includes(item.id));
      //费用
      let e = this.TOtableData.map(x => x.id);
      let newList5 = this.tableDatafive.filter(item => !e.includes(item.id));
      //其他
      let f = this.othertableData.map(x => x.id);
      let newList6 = this.tableDatasix.filter(item => !f.includes(item.id));

      let g = this.tableData1.map(x=>x.id)
      let newList7 = this.tableDataCANYU.filter(item => !g.includes(item.id));

      // 定义一个数组 push所有删除的数组
      let deletArrww = [];
      this.deletArr = deletArrww.concat(newList, newList2, newList3, newList4, newList5, newList6,newList7);
    },
    // 提交
    confim() {

      this.getDeletInfo();
      // if (Number(this.targetAmount) * 10000 <= Number(this.totalInvestment)) {
      //   this.$message.warning("项目目标总和应当大于各项投入合计，单位为：元，请确认填写是否无误");
      //   this.pageLoadFlag = false;
      //   return false;
      // }
      
      let id = this.type == "add" ? "0" : this.$route.query.id;
      let dlsId = this.type == "add" ? this.FXDLlnum : this.dlId;
      let fxIds = this.type == "add" ? this.FXName : this.FXId;
      let DLMDIds = this.type == "add" ? this.DLMDName : this.DLMDId;
      let processInstId = this.type == "add" ? "" : this.processInstId;
      let data = {
        id: id,
        marketCenterId: this.marketCenterId,//营销中心ID
        orgId: this.officeId,//办事处ID
        applyMonth: this.timeStart,//活动申请时间
        custInfoId: dlsId,//代理商ID
        activityTheme: this.therm,//活动主题
        activityPlace: this.address,//活动地点
        activityTarget: this.targetAmount,//活动目标
        activityAttributeId: this.activityAttributeId,//活动属性
        isAskRes: this.isAskRes, // 是否申请总部资源
        fxCust: fxIds,//分销商多选，List类型，传分销商ID
        cyShop:DLMDIds,//代理门店，List类型，传代理商ID
        totalMoney: this.totalInvestment,//各项投入合计
        adCompany: this.advertisement,//待制作广告公司
        bscManager: this.bscManager,//办事处经理ID
        content: this.content,//申请理由
        matklDtoList: this.tableData,//物料组数据
        mediaDtoList: this.MttableData, // 媒体数据
        tempDtoList: this.lctableData, // 临促数据
        giftDtoList: this.zptableData, // 赠品数据
        attachs: this.fileIds, // 附件id
        deleteAttachs:this.delFileId,
        processInstId: processInstId,
        // activityCode: this.activityCode,
        //  新添加两个类型
        tobDtoList: this.TOtableData,
        otherDtoList: this.othertableData,
        productLineDtoList:this.tableData1,
        // 删除的数据的id集合
        delDetails: this.type == "add" ? [] : this.deletArr.map(x => x.id).filter((j) => j),
        applyStartTime:this.ActivityStartTime,// 活动开始时间
        applyEndTime:this.ActivityEndTime,//活动结束时间

      };
      activityApplication(data).then(res => {
        if (res.data.code == 0) {
          this.$message.success(res.data.msg);
          this.visible = false;
          this.pageLoadFlag = false;
          // 关闭窗口之前刷新父页面
          window.opener.location.reload();
          this.timer = setTimeout(() => {  //设置延迟执行
            this.goback()
          }, 2000);
        } else {
          this.pageLoadFlag = false;
          this.$message.warning("提交失败," + res.data.msg);

        }
      });
    }


  }

};