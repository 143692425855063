import http from "@/utils/request";

// 获取列表
export function getList(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getActivityList.nd",
        data
    });
}

//   获取营销中心的列表
export function getYxList(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getMarketCenter.nd",
        data
    });
}

// 审批状态

export function getStatusList(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/getCheckStatus.nd",
        data
    });
}
// export function getExport(data) {
//     return http({
//         url: 'cts/ctsApi.nd',
//         method: 'get',
//         params:data,
//         contentType: "application/json;charset=UTF-8",
//         type: 'stringfy',
//         responseType: 'blob'
//     })
// }



// 导出

export function getExport(data) {
    return http({
        url: '/custAgentActivity/exportActivityList.nd',
        method: 'get',
        params: data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        responseType: 'blob'
    })
}

//  删除
export function delLine(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/deleteActivityById.nd",
        data
    });
}
export function terminalActivity(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/terminalActivityById.nd",
        data
    });
}

// 审核 
export function shLc(data) {
    return http({
        method: "post",
        url: "/custAgentActivity/agree.nd",
        data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    });
}

/**
 * 活动属性筛选条件
 * @param data
 * @returns {AxiosPromise}
 */
export function getActivityAttributeOptions(data) {
    return http({
        method: "get",
        url: "/custAgentActivity/getActivityAttribute.nd",
        data
    });
}
